html, body, #root {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}

.clickable {
  cursor: pointer;
}

.background {
  z-index: -10;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.3);
  }
}

.app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}

.panel {
  position: relative;
  margin: 5rem;
  border: 2px solid black;
  border-radius: 13px;
  box-sizing: border-box;
  background-color: rgba(255,255,255,1);

  .panel-button {
    @extend .clickable;
    text-align: center;
    white-space: nowrap;
    flex-grow: 1;
    padding: 1rem 3rem;

    &.active {
      border-bottom: 6px solid black;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
    }

    &:hover {
      background: black;
      color: white;
    }
  }
}

.row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main.panel {
  width: 55%;
  box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.15);

  form {

    &.error {

      input[type=text] {
        border-color: red;
      }
    }

    input {
      font-size: 1.3rem;
      outline: none !important;
    }

    input[type=text] {
      width: 35%;
      border: 2px solid black;
      border-radius: 3px;
      padding: .5rem;
      margin: 2rem 5rem;
      transition: width 0.5s, border-color .2s;

      &:focus {
        width: 75%;
        border-radius: 0;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
      }
    }

    .words {
      margin: 0 5rem;
      margin-bottom: 3rem;
      font-size: 1.3rem;
      text-align: left;
      font-weight: 486;

      li {
        cursor: pointer;

        &.error {
          color: orange;
        }

        &.spelling-error {
          color: red;
        }

        &:hover {
          text-decoration: line-through;
        }
      }
    }
  }
}

.footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem .5rem;
  font-size: .9rem;
  font-family: monospace !important;
  width: 99%;
  bottom: 0;
  background-color: white
}

@media screen and (max-width: 750px) {
  .main.panel {
    width: 80%;
  }

  .footer {
    flex-direction: column;

    span {
      margin: .2rem 0;

      &:before {
        content: "> ";
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .main.panel {
    width: 100%;
    min-height: 100%;

    .mode-buttons {
      flex-direction: column;

      li {
        padding: .5rem;
        margin: 0;

        &.active {
          border-bottom: 0;
          border-left: 25px solid black;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
        }

        &:last-of-type {
          border-top-right-radius: 10px;
        }
      }
    }
  }
}