.settings-container {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;

    h1 {
        text-align: center;
        margin: 0;
        margin-bottom: 2rem;
    }

    .settings-overlay {
        z-index: -10;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .settings-modal {
        background-color: white;
        border-radius: 10px;
        padding: 3rem 4rem;

        form {
            text-align: left;
        }

        .checkbox {
            display: block;
        }
    }
}

.settings-toggle {
    cursor: pointer;
    background: url("assets/settings.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    transform: translateX(-120%)
}